
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'

@Component({
  name: 'AddOwner'
})
export default class extends Vue {
  @Prop() readonly Visible!: boolean
  @Prop() readonly id!: string

  private info = {}

  private supplierData = []
  private submitShow = false

  private userIdList = []

  private rules= {
    userIdList: [
      { required: true, message: '请选择业主数据', trigger: 'blur' }
    ]
  }

  @Watch('id')
  isTrue (val: string) {
    if (val) {
      this.getRoomDetail()
    }
  }

  getRoomDetail () {
    this.$axios.get(this.$apis.smartHouse.selectRoomByRoomId, {
      roomId: this.id
    }).then((res) => {
      this.info = res
    })
  }

  created () {
    // console.log('参数', this.data)
    this.loadUserList()
  }

  loadUserList () {
    this.$axios.get(this.$apis.common.getRoleUserList, {
      isAdmin: 0
    }).then(res => {
      this.userIdList = res.map((item: any) => {
        return {
          userId: item.userId,
          userName: `${item.userName}${item.mobile ? '(' : ''}${item.mobile ? item.mobile : ''}${item.mobile ? ')' : ''}`
        }
      }) || []
    })
  }

  saveForm () {
    (this.$refs.formData as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.smartHouse.updateRoomUser, this.info).then(() => {
          this.$message.success('绑定成功')
          this.handleClose()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  handleClose () {
    console.log('关闭')
    this.$emit('update:Visible', false)
    this.$emit('update:id', '')
    this.info = {};
    (this.$refs.formData as ElForm).resetFields()
  }
}
