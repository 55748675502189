
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'

@Component({
  name: 'AddDevice'
})
export default class extends Vue {
  @Prop() readonly Visible!: boolean
  @Prop() readonly data!: any

  private info = {
    roomId: '',
    valveDevice: '',
    soilDevice: '',
    waterMeterDevice: ''
  }

  private svalveDeviceList = []
  private soilDeviceList = []
  private waterMeterDeviceList = []

  private submitShow = false

  private rules= {
    // valveDevice: [
    //   { required: true, message: '请选择电磁阀', trigger: 'change' }
    // ]
  }

  get userName () {
    let name = ''
    if (this.$store.state.userInfo && this.$store.state.userInfo.userName) {
      name = this.$store.state.userInfo.userName
    } else if (window.sessionStorage.getItem('userInfo')) {
      name = JSON.parse(window.sessionStorage.getItem('userInfo') as any).userName
    }
    return name
  }

  @Watch('Visible')
  isTrue (val: string) {
    // val && this.getDetail()
    if (val) {
      // console.log('参数', this.data)
      this.info = JSON.parse(JSON.stringify(this.data))
      this.getSvalveList()
      this.getSoilList()
      this.getWaterList()
      // this.getRoomDetail()
    }
  }

  // getRoomDetail () {
  //   this.$axios.get(this.$apis.smartHouse.selectSqSoilInfoByPage).then((res) => {
  //     this.soilDeviceList = res.soil.list.map((item: any) => {
  //       return {
  //         deviceName: item.deviceName + '(' + item.deviceNumber + ')',
  //         deviceNumber: item.deviceNumber
  //       }
  //     }) || []
  //   })
  // }

  getSvalveList () {
    this.$axios.get(this.$apis.smartHouse.selectSqIrrigationValveByPage, {
      notRoom: 1,
      roomId: this.info.roomId
    }).then((res) => {
      // console.log('res', res)
      this.svalveDeviceList = res.list.map((item: any) => {
        return {
          deviceName: item.deviceName + '(' + item.deviceNumber + ')',
          deviceNumber: item.deviceNumber
        }
      }) || []
    })
  }

  getSoilList () {
    this.$axios.get(this.$apis.smartHouse.selectSqSoilInfoByPage, {
      notRoom: 1,
      roomId: this.info.roomId
    }).then((res) => {
      this.soilDeviceList = res.soil.list.map((item: any) => {
        return {
          deviceName: item.deviceName + '(' + item.deviceNumber + ')',
          deviceNumber: item.deviceNumber
        }
      }) || []
    })
  }

  getWaterList () {
    this.$axios.get(this.$apis.smartHouse.selectSqWaterMeterInfoByPage, {
      notRoom: 1,
      roomId: this.info.roomId
    }).then((res) => {
      this.waterMeterDeviceList = res.waterMeter.list.map((item: any) => {
        return {
          deviceName: item.deviceName + '(' + item.deviceNumber + ')',
          deviceNumber: item.deviceNumber
        }
      }) || []
    })
  }

  saveForm () {
    (this.$refs.formData as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.smartHouse.updateRoomDevice, this.info).then(() => {
          this.$message.success('绑定成功')
          this.handleClose()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  handleClose () {
    (this.$refs.formData as ElForm).resetFields()
    this.$emit('update:Visible', false)
    // console.log('this.info', this.info, this.data)
  }
}
