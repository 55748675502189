
import { Component, Vue } from 'vue-property-decorator'
import { SubmitInfo } from '@/types/department'
import { KeepAlive } from '@/utils/decorators'
import Owner from './ownerDialog.vue'
import Device from './deviceDialog.vue'

@Component({
  components: { Owner, Device }
})
@KeepAlive
export default class Department extends Vue {
  private tableData = []
  private buildList = []

  private loading = false

  private props = {
    value: 'buildingId',
    label: 'buildingName',
    children: 'childNode'
  }

  private ownerDialogVisible = false
  private deviceDialogVisible = false

  private roomId = '' // 添加业主使用

  private houseInfo: SubmitInfo = {
    deptId: '',
    deptName: '',
    parentDeptId: ''
  }

  private rules = {
    deptName: [{ required: true, message: '请输入组织名称', trigger: ['blur'] }]
  }

  private submitShow = false

  private searchInfo = {
    buildingId: '',
    roomName: '',
    deviceNumber: '',
    userObj: ''
  }

  private total = 0
  private page = 1
  private size = 10

  get userName () {
    let name = ''
    if (this.$store.state.userInfo && this.$store.state.userInfo.userName) {
      name = this.$store.state.userInfo.userName
    } else if (window.sessionStorage.getItem('userInfo')) {
      name = JSON.parse(window.sessionStorage.getItem('userInfo') as any).userName
    }
    return name
  }

  created () {
    this.getBuildList()
    this.getRoomList()
  }

  filterDevice (value: any) {
    const valveDevice = value.valveDevice ? `电磁阀设备(${value.valveDevice})` : ''
    const soilDevice = value.soilDevice ? `土壤设备(${value.soilDevice})` : ''
    const waterMeterDevice = value.waterMeterDevice ? `水表(${value.waterMeterDevice})` : ''
    const arr = []
    let str = ''
    if (valveDevice) {
      arr.push(valveDevice)
    }
    if (soilDevice) {
      arr.push(soilDevice)
    }
    if (waterMeterDevice) {
      arr.push(waterMeterDevice)
    }
    arr.forEach((item: any, index: any) => {
      if (arr.length - 1 === index) {
        str = str + item
      } else {
        str = str + item + ','
      }
    })
    return str
  }

  getBuildList () {
    this.$axios.get(this.$apis.smartHouse.selectBuildingByList).then((res) => {
      this.buildList = res.list || []
    })
  }

  getRoomList (flag?: any) {
    if (flag) {
      // console.log('去除树高亮')
      this.$nextTick(() => {
        (this.$refs.tree as any).setCurrentKey(null)
      })
    }
    this.$axios.get(this.$apis.smartHouse.selectRoomByList, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  nodeClick (node: any, data: any) {
    // console.log('123123', node, data)
    this.page = 1
    if (data) {
      // console.log('点击楼栋')
      this.searchInfo.buildingId = node.buildingId
      this.getRoomList()
    } else {
      // console.log('点击小区')
      this.searchInfo.buildingId = ''
      this.getRoomList(true)
    }
  }

  onAddBuild () {
    this.$router.push({ name: 'addBuild' })
  }

  onEditBuild (node: any, data: any) {
    this.$router.push(
      {
        path: '/smartHouse/build/edit',
        query: {
          buildingId: data.buildingId
        }
      }
    )
  }

  onDeleteBuild (node: {data: {buildingId: string}}) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.smartHouse.deleteBuilding, {
        buildingId: node.data.buildingId
      })
        .then(() => {
          this.$message.success('删除成功')
          this.getBuildList()
        })
    }).catch((err) => {
      console.log(err)
    })
  }

  onSearch () {
    this.page = 1
    // this.personnelGet()
    this.getRoomList()
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'personnelDetail',
      params: {
        id: id
      }
    })
  }

  onAddOwner (roomId: any) {
    this.ownerDialogVisible = true
    this.roomId = roomId
  }

  onUpdate (id: string) {
    this.$router.push({
      name: 'personnelUpdate',
      params: {
        id: id
      }
    })
  }

  onBindingDevice (data: any) {
    this.deviceDialogVisible = true
    // this.houseInfo = JSON.parse(JSON.stringify(data))
    this.houseInfo = data
  }

  onDeleteHouse (roomId: string) {
    this.$confirm('是否确认删除该房间？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.smartHouse.deleteRoom, {
        roomId
      }).then(() => {
        this.$message.success('操作成功')
        this.page = 1
        this.getRoomList()
      })
    })
  }
}
